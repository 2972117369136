<template>
    <div>
        <el-dialog custom-class="gl_dialog_wrapper" append-to-body title="编辑品牌" :visible.sync="dialogEdit" width="34%"
            :before-close="handleClose">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户名" prop="user">
                    <el-input v-model="ruleForm.user"></el-input>
                </el-form-item>

                <el-form-item label="用户名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="车型图标" prop="logo">
                    <el-upload :disabled="ruleForm.logo ? true : false" class="avatar-uploader"
                        action="https://apisgu.chinaucv.com/staffPlatform/upload/uploadImage" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :headers="uploadHeaders"
                        accept="image/jpg,image/jpeg,image/png" name="image" :data="{ type: 0, vehicleModelId: 0 }"
                        :multiple="false">
                        <!-- <img v-if="ruleForm.logo" :src="ruleForm.logo" class="avatar"> -->
                        <div style="background: #fff;">
                            <el-image class="avatar" :zIndex="9999" v-if="ruleForm.logo" :src="ruleForm.logo"
                                :preview-src-list="[ruleForm.logo]" fit="contain">
                            </el-image>
                        </div>
                        <i v-if="!ruleForm.logo" class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="avatar_delete" v-if="ruleForm.logo" @click.stop="onClickDeleteImage()">
                            <img style="width:14px;height: 14px;" src="../../assets/error.png" alt="">
                        </div>
                    </el-upload>
                    <div class="gl_html_text">{{ htmlText }}</div>
                </el-form-item>
                <el-form-item label="用户名" prop="user">
                    <el-input v-model="ruleForm.user"></el-input>
                </el-form-item>

                <el-form-item label="用户名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="form_item_button">
                        <!-- <el-button @click="resetForm('ruleForm')">重 置</el-button> -->
                        <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["dialogEdit", "dialogEditData"],
    data() {
        return {
            ruleForm: {
                user: '1',
                name: '',
                phone: '',
                pass: '',
                logo: '',
                checkPass: '',
            },
            rules: {
                user: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 1, max: 7, message: '长度在 7 个字符以内', trigger: 'blur' }
                ],

            },
            uploadHeaders: {},
            htmlText: '请上传100*100px，大小 < 500kb的图片'
        }
    },
    created() {
        // 获取token
        let token = sessionStorage.getItem("token");
        this.uploadHeaders = { token };
    },
    mouted() {

    },
    methods: {
        // 上传照片
        handleAvatarSuccess(file) {
            console.log(file.content.urls);
            this.ruleForm.logo = file.content.urls[0]
        },
        beforeAvatarUpload() {

        },
        // 关闭弹窗
        handleClose() {
            this.$emit('handleClose', false);
        },
    },
}
</script>
<style scoped>
.avatar-uploader {
    width: 90px;
    height: 90px;
    position: relative;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border: 1px solid #CCCCCC;
    overflow: hidden;
}

.avatar {
    width: 90px;
    height: 90px;
    display: block;
}

.avatar_delete {
    position: absolute;
    top: -10px;
    right: 0;
}

.gl_html_text {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E61C1C;
}

.gl_dialog_wrapper {
    padding: 0;
}

.form_item_button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
</style>