<template>
    <div id="app">
        <!-- 顶部搜索 -->
        <div class="gl_top_view">
            <div class="gl_top_input">
                <div class="gl_top_fal">
                    <div class="title">品牌名称</div>
                    <el-input class="input-with" v-model="input" placeholder="请输入"></el-input>
                </div>
                <div class="gl_top_fal">
                    <div class="title">企业名称</div>
                    <el-input class="input-with" v-model="input" placeholder="请输入"></el-input>
                </div>
                <div class="gl_top_fal">
                    <div class="title">logo图片</div>
                    <el-select class="input-with input_comm" v-model="input" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="gl_top_buttons">
                <div class="screen">筛选</div>
                <div class="resetting">重置</div>
            </div>
        </div>
        <!-- 内容部分 -->
        <div class="gl_main_view">
            <!-- <div class="gl_button" @click="onClickUser('add')">添加团队</div> -->
            <el-table :data="tableData" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                <el-table-column prop="name" label="序号" width="80">
                </el-table-column>
                <el-table-column prop="name" label="品牌名称" width="120">
                </el-table-column>
                <el-table-column prop="name" label="logo">
                </el-table-column>
                <el-table-column prop="name" label="企业名称">
                </el-table-column>
                <el-table-column prop="name" label="首字母" width="80">
                </el-table-column>
                <el-table-column prop="name" label="名称全拼">
                </el-table-column>
                <el-table-column prop="name" label="最后编辑时间">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClickEdit(scope.row)" type="text">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="gl_pagination">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage4" :page-sizes="[100, 200, 300, 400]" :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <beandListEdit @handleClose="handleClose" :dialogEdit="dialogEdit" :dialogEditData="dialogEditData">
        </beandListEdit>
    </div>
</template>

<script>
import beandListEdit from "@/views/vehicleModelLibrary/beandListEdit.vue"
export default {
    // 编辑
    components: {
        beandListEdit
    },
    data() {
        return {
            input: "",
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            currentPage4: 4,
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-02',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-04',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-01',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-08',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-06',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }, {
                date: '2016-05-07',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }],
            total: 0,
            // 团队编辑
            dialogEdit: false,
            dialogEditData: {},
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        // 关闭详情页
        handleClose(msg) {
            this.dialogEdit = msg;
        },
        // 编辑
        handleClickEdit(msg) {
            this.dialogEdit = true;
            this.dialogEditData = msg;
        },
    },
}
</script>
<style scoped>
.gl_top_view {
    width: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 0px 10px #BFDFF7;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 20px 20px;
}

.gl_top_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal {
    /* width: 284px; */
    width: 30%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal>.title {
    min-width: 64px;
    max-width: 64px;
    margin-right: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: right;
}

.gl_top_fal .input-with {
    width: 100%;
    font-size: 16px !important;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}

.gl_top_buttons {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.gl_top_buttons>div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 115px;
    height: 34px;
    opacity: 1;
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
}

.gl_top_buttons>.screen {
    background: rgba(0, 131, 227);
    /* border: 1px solid #1883E3; */
    color: #FFFFFF;
}

.gl_top_buttons>.resetting {
    border: 1px solid #1883E3;
    color: #1883E3;
    margin-left: 20px;
}

.gl_main_view {
    width: 100%;
    background: rgba(255, 255, 255);
    opacity: 1;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.gl_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    opacity: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.gl_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
</style>
<style>
.input_comm input {
    width: 100%;
    font-size: 16px !important;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}
</style>